export const carouselItems = [
    {
        title: {
            French: 'Nettoyage Professionnel',
            Spanish: 'Limpieza Profesional',
            English: 'Professional Cleaning'
        },
        description: {
            French: 'La perfection réside dans les détails : notre engagement.',
            Spanish: 'La perfección está en los detalles: nuestro compromiso.',
            English: 'Perfection lies in the details: our commitment.'
        },
        imageUrl: 'https://koala.sh/api/image/v2-5r9ff-1pquq.jpg?width=1344&height=768&dream'
    },
    {
        title: {
            French: 'Chalet/Airbnb',
            Spanish: 'Chalet/Airbnb',
            English: 'Chalet/Airbnb'
        },
        description: {
            French: 'Nous prenons soin de votre réputation : nettoyage de qualité pour hôtels et locations.',
            Spanish: 'Cuidamos de su reputación: limpieza de calidad para hoteles y alojamientos.',
            English: 'We take care of your reputation: quality cleaning for hotels and rentals.'
        },
        imageUrl: 'https://thepinkwand.com/wp-content/uploads/2023/09/airbnb-3399753_1920.jpg'
    },
    {
        title: {
            French: 'Bureaux et Entreprises',
            Spanish: 'Oficinas y Empresas',
            English: 'Offices and Businesses'
        },
        description: {
            French: 'Des espaces impeccables pour des affaires florissantes : nos services pour bureaux et entreprises.',
            Spanish: 'Espacios impecables para negocios prósperos: nuestros servicios para oficinas y empresas.',
            English: 'Impeccable spaces for thriving businesses: our services for offices and businesses.'
        },
        imageUrl: 'https://fastmaidservice.com/wp-content/uploads/2022/12/No-Need-to-Buy-Expensive-Cleaning-Supplies.png'
    },
    {
        title: {
            French: 'Lavage haute pression',
            Spanish: 'Lavado de alta presión',
            English: 'High pressure washing'
        },
        description: {
            French: 'Pression maximale pour une propreté totale : des locaux impeccables, des affaires qui cartonnent',
            Spanish: 'Lavado de alta presión: Espacios impecables para negocios prósperos y ambientes que inspiran confianza!',
            English: 'High-pressure washing for spaces that shine and businesses that thrive! Trust our expertise for impeccable results.'
        },
        imageUrl: 'https://xtremecleancostarica.com/wp-content/uploads/2020/10/lavadoapresion-scaled.jpg'
    }
];


// 