export const data_details_translations = {
    description_residentiel_fra: `
        Profond
        Service de nettoyage minutieux, désinfection et nettoyage en profondeur dans les salles de bains et la cuisine, axé sur les détails dans les chambres et les espaces communs, POUR GÉNÉRER LA CONFIANCE, que les espaces de votre maison seront prêts pour que toute votre famille puisse en profiter au quotidien.
        Recommandé 1 fois par mois
        Comprend - (*) cuisine, four et hotte, micro-ondes, réfrigérateur.
        `,
    description_residentiel_list_fra: [
        'Dépoussiérage, nettoyage et désinfection des plafonds et des coins',
        'Dépoussiérage, nettoyage et désinfection des vitrines et des étagères',
        'Dépoussiérage, nettoyage et désinfection des tables, des armoires et des surfaces',
        'Dépoussiérage et nettoyage des décorations, de la télévision, des lampes, etc.',
        'Dépoussiérage et nettoyage des plinthes',
        "Nettoyage des fenêtres à l'intérieur.",
        'Cuisine : nettoyage et désinfection des appareils (*), des armoires, du lave-vaisselle et des surfaces',
        'Salle de bain : nettoyage et désinfection des toilettes, des douches, des baignoires, des lavabos, des armoires et des étagères.',
        'Aspiration des sols, des escaliers et des tapis',
        'Nettoyage et désinfection des sols, des escaliers, des rampes et des poignées de porte.',
        "Nettoyage avec coût supplémentaire : enlèvement des taches sur les murs, lavage des tapis, nettoyage des tapisseries de canapés, lavage des rideaux, lavage des fenêtres à l'extérieur avec un nettoyeur haute pression."
    ],
    description_residentiel_list_eng: [
        'Dusting, cleaning, and disinfection of ceilings and corners',
        'Dusting, cleaning, and disinfection of display cases and shelves',
        'Dusting, cleaning, and disinfection of tables, cabinets, and surfaces',
        'Dusting and cleaning of decorations, TV, lamps, etc.',
        'Dusting and cleaning of baseboards',
        'Interior window cleaning.',
        'Kitchen: cleaning and disinfection of appliances (*), cabinets, dishwasher, and surfaces',
        'Bathroom: cleaning and disinfection of toilet, showers, tubs, sinks, cabinets, and shelves.',
        'Vacuuming floors, stairs, and carpets',
        'Cleaning and disinfection of floors, stairs, handrails, and door handles.',
        'Cleaning with additional cost: wall stain removal, carpet washing, upholstery cleaning, curtain washing, outside window washing with high-pressure hydro cleaning.'
    ],
    description_residentiel_list_esp: [
        'Despolvado, limpieza y desinfección de cielos y rincones',
        'Despolvado, limpieza y desinfección de vitrinas y repisas',
        'Despolvado, limpieza y desinfección de mesas, gabinetes y superficies',
        'Despolvado y limpieza de decoraciones, tv, lámparas, etc.',
        'Despolvado y limpieza de guardapolvos',
        'Limpieza de ventanas por interior.',
        'Cocina: limpieza y desinfección de aparatos (*), gabinetes, lavaplatos y superficies',
        'Baño: limpieza y desinfección de inodoro, duchas, tinas, lavamanos, gabinetes y repisas.',
        'Aspirado de suelos, escaleras y alfombras',
        'Limpieza de desinfección de suelos, escaleras y pasamanos y picaportes de puertas.',
        'Limpieza con costo adicional: desmanchado de muros, lavado de alfombras, limpieza de tapices de sillones, lavado de cortinas, lavado de ventanas por fuera con hidro presión.'
    ],
    description_residentiel_eng: `
        Deep
        Thorough cleaning service, disinfection, and deep cleaning in bathrooms and kitchen, focusing on detail in bedrooms and common areas, TO GENERATE TRUST, that the spaces of your home will be ready for your whole family to enjoy day by day.
        Recommended once a month
        Includes
        (*) kitchen, oven and hood, microwave, refrigerator.        
        `,
    description_residentiel_esp: `
            Profundo
            Servicio de limpieza minucioso, desinfección y limpieza profunda en baños y cocina,
            enfocado al detalle en habitaciones y áreas comunes, QUE GENERE LA CONFIANZA,
            en que los espacios de su hogar estarán aptos para que toda su familia pueda
            disfrutarlos día a día.
            Recomendado 1 vez por mes
            Incluye
            
            (*) cocina, horno y campana, microondas, refrigerador.
        `,
    description_commercial_esp: `
        Servicio de aseo minucioso para lugares de alto tránsito. Nuestro anhelo es que cada
        uno de nuestros clientes sienta que su lugar de trabajo logra el estándar de bien estar
        para con sus propios beneficiarios. Nos sentimos parte de su propio negocio.
        Recomendado al menos 1 vez por semana
        Incluye
        `,
    description_commercial_list_esp: [
        'Remoción de basura y reciclaje',
        'Aspirado y barrido de pisos',
        'Limpieza y Desinfección de pisos',
        'Limpieza y Desinfección de superficies',
        'Limpieza de superficies de vidrio',
        'Limpieza y Desinfección de repisas',
        'Limpieza y desinfección de puertas y manillas',
        'Limpieza y desinfección de baños y artefactos de baño.',
        'Limpieza de lámparas y decorativos tales como adornos, cuadros, relojes y colgantes',
        'Limpieza de bordes y guardapolvos',
        'Limpieza y desinfección de sala de cocina o comedor',
        'Aspirado de rincones',
        'Abastecimiento de enseres generales',

        'Servicios de que pueden incluir con un costo adicional',
        'Limpieza y desmanchado de muros',

        'Lavado y limpieza de ventanas y ventanales',
        'Lavado de tapiz y alfombras',
        'Limpieza y lavado de sillones',
    ],
    description_commercial_eng: `
    Thorough cleaning service for high-traffic areas. Our desire is for each of our clients to feel that their workplace achieves the standard of well-being for their own beneficiaries. We feel part of your own business.
    Recommended at least once a week
    Includes
        `,
    description_commercial_list_eng: [
        'Trash and recycling removal',
        'Vacuuming and sweeping floors',
        'Floor cleaning and disinfection',
        'Surface cleaning and disinfection',
        'Cleaning of glass surfaces',
        'Shelf cleaning and disinfection',
        'Cleaning and disinfection of doors and handles',
        'Cleaning and disinfection of bathrooms and bathroom fixtures.',
        'Cleaning of lamps and decorative items such as ornaments, paintings, clocks, and hangings',
        'Edge and baseboard cleaning',
        'Cleaning and disinfection of kitchen or dining room area',
        'Corner vacuuming',
        'Supply of general supplies',

        'Services that can be included for an additional cost',
        'Wall cleaning and stain removal',

        'Window and window frame washing',
        'Upholstery and carpet washing',
        'Cleaning and washing of sofas',
    ],
    description_commercial_fra: `
    Service de nettoyage méticuleux pour les zones à fort achalandage. Notre souhait est que chacun de nos clients ressente que son lieu de travail atteint le standard de bien-être pour ses propres bénéficiaires. Nous nous sentons partie intégrante de votre propre entreprise.
    Recommandé au moins une fois par semaine
    Comprend
        `,
    description_commercial_list_fra:
        [
            'Enlèvement des déchets et recyclage',
            'Aspiration et balayage des planchers',
            'Nettoyage et désinfection des planchers',
            'Nettoyage et désinfection des surfaces',
            'Nettoyage des surfaces en verre',
            'Nettoyage et désinfection des tablettes',
            'Nettoyage et désinfection des portes et poignées',
            'Nettoyage et désinfection des toilettes et des accessoires de salle de bain.',
            'Nettoyage des lampes et des éléments décoratifs tels que les ornements, tableaux, horloges et pendentifs',
            'Nettoyage des rebords et des moulures',
            'Nettoyage et désinfection de la cuisine ou de la salle à manger',
            'Aspiration des coins',
            'Approvisionnement en fournitures générales',

            'Services pouvant être inclus moyennant des frais supplémentaires',
            'Nettoyage et détachage des murs',

            'Lavage et nettoyage des fenêtres et des vitrages',
            'Lavage des tapis et des moquettes',
            'Nettoyage et lavage des fauteuils',
        ],
    description_chalet_esp: `
        Servicio de limpieza minuciosa y al detalle de hospedajes para pasajeros. Nuestra gran
        meta es lograr que cada pasajero, se lleve la mejor experiencia en atención y confort.
        Hacer de sus huéspedes los nuestros.

        Servicio de limpieza incluye:
        `,
    description_chalet_list_esp: [
        'Remoción de basura y reciclaje',
        'Aspirado de pisos',
        'Limpieza y desinfección de pisos',
        'Limpieza y Desinfección de superficies',
        'Limpieza de superficies de vidrio',
        'Limpieza y Desinfección de repisas y cajones',
        'Limpieza y desinfección de cocina y artefactos de cocina tales como cocina, horno,',
        'campana, microondas, refrigerador. (*)',
        'Aspirado de closets',
        'Limpieza y desinfección de baños y artefactos de baño.',
        'Limpieza de lámparas y decorativos tales como adornos, cuadros, relojes y colgantes (*)',
        'Limpieza de bordes y guardapolvos',
        'Aspirado de rincones',
        'Lavado de sabanas y toallas (juego usado por los huéspedes)',
        'Reposición de implementos básicos (jabón, shampoo, acondicionador, lavaloza, pastillas de lavavajilla, esponjas, papel de baño, papel de mano, bolsas de basura y reciclaje, escoba/pala, trapero, ampolletas y baterias).',
        'Armado de camas tipo hotelería',
        'Organización de la vajilla y los espacios comunes',
        ' Regalo de bienvenida a los huéspedes + tarjeta de agradecimiento.',
        ' Servicios que se pueden incluir con un costo adicional',
        'Limpieza y desmanchado de muros',
        'Lavado y limpieza de ventanas y ventanales',
        'Limpieza de colchones',
        'Lavado de tapiz y alfombras',
        'Limpieza de sillones',
        'Lavado de cortinas',
        'Lavado de cubrecamas',
        'Limpieza y organización de terrazas y espacios exteriores',

        'Organización de ambientes',
    ],
    description_chalet_eng: `
    Thorough and detailed cleaning service for guest accommodations. Our ultimate goal is to ensure that every guest has the best experience in attention and comfort. Making their guests ours.
    Cleaning service includes:
        `,
    description_chalet_list_eng: [
        'Garbage and recycling removal',
        'Floor vacuuming',
        'Floor cleaning and disinfection',
        'Surface cleaning and disinfection',
        'Glass surface cleaning',
        'Shelf and drawer cleaning and disinfection',
        'Kitchen cleaning and kitchen appliances such as stove, oven, hood, microwave, refrigerator (*)',
        'Closet vacuuming',
        'Bathroom cleaning and bathroom fixtures disinfection',
        'Lamp and decorative item cleaning such as ornaments, paintings, clocks, and hanging items (*)',
        'Edge and baseboard cleaning',
        'Corner vacuuming',
        'Bed sheets and towels washing (set used by guests)',
        'Replenishment of basic supplies (soap, shampoo, conditioner, dishwashing liquid, dishwasher tablets, sponges, toilet paper, hand towels, garbage and recycling bags, broom/dustpan, mop, light bulbs, and batteries).',
        'Hotel-style bed making',
        'Dishware and common area organization',
        'Welcome gift to guests + thank you card.',
        'Services that can be included at an additional cost:',

        'Wall cleaning and stain removal',
        'Window and windowpane washing and cleaning',
        'Mattress cleaning',
        'Upholstery and carpet washing',
        'Sofa cleaning',
        'Curtain washing',
        'Bedspread washing',
        'Terrace and outdoor space cleaning and organization',
        'Ambient organization',
    ],
    description_chalet_fra: `
    Service de nettoyage minutieux et détaillé pour les hébergements de voyageurs. Notre objectif ultime est de garantir que chaque client passe la meilleure expérience en termes d'attention et de confort. Faire de leurs invités les nôtres.

    Le service de nettoyage comprend :
        `,
    description_chalet_list_fra: [
        ' Enlèvement des déchets et recyclage',
        'Aspiration des sols',
        'Nettoyage et désinfection des sols',
        'Nettoyage et désinfection des surfaces',
        'Nettoyage des surfaces en verre',
        'Nettoyage et désinfection des tablettes et tiroirs',
        'Nettoyage et désinfection de la cuisine et des appareils de cuisine tels que cuisinière, four, hotte, micro-ondes, réfrigérateur (*)',
        'Aspiration des placards',
        'Nettoyage et désinfection des salles de bains et des appareils sanitaires',
        'Nettoyage des lampes et des objets décoratifs tels que les ornements, peintures, horloges et objets suspendus (*)',
        'Nettoyage des bords et des plinthes',
        'Aspiration des coins',
        'Lavage des draps et des serviettes (ensemble utilisé par les invités)',
        'Reconstitution des fournitures de base (savon, shampooing, revitalisant, liquide vaisselle, pastilles pour lave-vaisselle, éponges, papier toilette, serviettes en papier, sacs poubelle et recyclage, balai/pelle, serpillière, ampoules et piles).',
        'Lit fait à la manière des hôtels',
        'Organisation de la vaisselle et des espaces communs',
        'Cadeau de bienvenue aux invités + carte de remerciement.',
        'Services pouvant être inclus moyennant des frais supplémentaires :',

        'Nettoyage et détachage des murs',
        'Lavage et nettoyage des fenêtres et des vitrages',
        'Nettoyage des matelas',
        'Lavage des tapis et des moquettes',
        'Nettoyage des canapés',
        'Lavage des rideaux',
        'Lavage des couvre-lits',
        'Nettoyage et organisation des terrasses et des espaces extérieurs',
        'Organisation des environnements',
    ],

    description_broker_esp: `
    Servicio de limpieza y desinfección minuciosos y detallista. Sabemos lo difícil que es
    recibir tu casa en condiciones que jamás hubieras permitido tener. Como empresa
    queremos que sientan el compromiso de que para nosotros es tan importante su casa
    como la nuestra. Nuestro deseo que nuestros clientes nos sientan aliados de sus
    proyectos.
    Servicio de limpieza incluye:
        `,
    description_broker_list_esp: [
        ' Remoción de basura y reciclaje',
        'Aspirado de pisos',
        'Limpieza y desinfección de pisos',
        'Limpieza y Desinfección de superficies',
        'Limpieza de superficies de vidrio',
        'Limpieza y Desinfección de repisas',
        'Limpieza y desinfección de cocina y artefactos de cocina tales como cocina, horno,',
        'campana, microondas, refrigerador. (*)',
        'Aspirado de closets',
        'Limpieza y desinfección de baños y artefactos de baño.',
        'Limpieza de lámparas y decorativos tales como adornos, cuadros, relojes y colgantes (*)',
        'Limpieza de bordes y guardapolvos',
        'Aspirado de rincones',
        'Remoción de manchas en muros',
        'Servicio con costo adicional',
        'Repintado de muros (blanco)',
    ],
    description_broker_eng: `
    Thorough and detailed cleaning and disinfection service. We understand how challenging it is to receive your home in conditions that you would never allow. As a company, we want you to feel the commitment that your home is as important to us as our own. Our desire is for our clients to see us as allies in their projects.

    Cleaning service includes:
        `,
    description_broker_list_eng: [
        'Garbage and recycling removal',
        'Floor vacuuming',
        'Floor cleaning and disinfection',
        'Surface cleaning and disinfection',
        'Glass surface cleaning',
        'Shelf cleaning and disinfection',
        'Kitchen cleaning and kitchen appliances such as stove, oven, hood, microwave, refrigerator (*)',
        'Closet vacuuming',
        'Bathroom cleaning and bathroom fixtures disinfection',
        'Lamp and decorative item cleaning such as ornaments, paintings, clocks, and hanging items (*)',
        'Edge and baseboard cleaning',
        'Corner vacuuming',
        'Wall stain removal',
        'Service with an additional cost',
        'Wall repainting (white)',
    ],
    description_broker_fra: `
    Service de nettoyage et de désinfection minutieux et détaillé. Nous comprenons à quel point il est difficile de recevoir votre maison dans des conditions que vous n'auriez jamais permises. En tant qu'entreprise, nous voulons que vous ressentiez l'engagement selon lequel votre maison est aussi importante pour nous que la nôtre. Notre désir est que nos clients nous perçoivent comme des alliés dans leurs projets.

    Le service de nettoyage comprend :
        `,

    description_broker_list_fra: [
        'Enlèvement des déchets et recyclage',
        'Aspiration des sols',
        'Nettoyage et désinfection des sols',
        'Nettoyage et désinfection des surfaces',
        'Nettoyage des surfaces en verre',
        'Nettoyage et désinfection des tablettes',
        'Nettoyage et désinfection de la cuisine et des appareils de cuisine tels que cuisinière, four, hotte, micro-ondes, réfrigérateur (*)',
        'Aspiration des placards',
        'Nettoyage et désinfection des salles de bains et des appareils sanitaires',
        'Nettoyage des lampes et des objets décoratifs tels que les ornements, peintures, horloges, et objets suspendus (*)',
        'Nettoyage des bords et des plinthes',
        'Aspiration des coins',
        'Enlèvement des taches sur les murs',
        'Service avec un coût supplémentaire',
        'Repeinture des murs (blanc)',
    ],
    description_AC_esp: `
    Servicio de limpieza, desinfección y remoción de escombros post
renovación/construcción.
Luego del arduo trabajo de construir o renovar un inmueble, quede las ganas de poder
disfrutarlo. Por esto, nuestro trabajo es que puedas disfrutar del lugar de tus sueños y
dejes todo lo que implica la post renovación/construcción en nuestras manos.

Servicio de limpieza incluye
        `,
    description_AC_list_esp: [
        'Remoción de escombros livianos tales como, bolsas de escombros.',
        'Limpieza de residuos de construcción en el piso (yeso, pintura, pasta muro, etc.)',
        'Limpieza de residuos de construcción en los muros',
        'Limpieza de residuos de construcción en las ventanas',
        'Lavado de ventanas (*)',
        'Remoción de basura y reciclaje',
        'Aspirado de pisos',
        'Lavado, secado y desinfección de pisos',
        'Limpieza y Desinfección de superficies',
        'Limpieza de superficies de vidrio',
        'Limpieza y Desinfección de gabinetes, cajones y repisas',
        'Limpieza y desinfección de cocina y artefactos de cocina (*)',
        'Limpieza y desinfección de closets',
        'Lavado, secado y desinfección de baños y artefactos de baño.',
        'Limpieza y desinfección de lámparas y decorativos (*)',
        'Limpieza de bordes y guardapolvos',
        'Aspirado de rincones (*) despolvado de artefactos de cocina (cocina, horno, refrigerador, microondas y campana) y decorativos (cuadros, relojes, colgantes).',
    ],
    description_AC_eng: `
    Cleaning, disinfection, and debris removal service post renovation/construction.
    After the hard work of building or renovating a property, there is a desire to be able to enjoy it. Therefore, our job is to ensure that you can enjoy the place of your dreams and leave everything related to post-renovation/construction in our hands.
    
    Cleaning service includes:
        `,
    description_AC_list_eng: [
        'Removal of lightweight debris such as debris bags.',
        ' Cleaning of construction residues on the floor (plaster, paint, wall compound, etc.)',
        'Cleaning of construction residues on the walls',
        'Cleaning of construction residues on the windows Window washing (*)',
        'Garbage and recycling removal',
        'Floor vacuuming',
        'Floor washing, drying, and disinfection',
        'Surface cleaning and disinfection',
        'Glass surface cleaning',
        'Cleaning and disinfection of cabinets, drawers, and shelves',
        'Kitchen cleaning and kitchen appliances disinfection (*)',
        'Closet cleaning and disinfection',
        'Bathroom washing, drying, and disinfection.',
        'Cleaning and disinfection of lamps and decorative items (*)',
        'Edge and baseboard cleaning',
        'Corner vacuuming (*) Kitchen appliances dusting (stove, oven, refrigerator, microwave, and hood) and decorative items (paintings, clocks, hanging items).',
    ],
    description_AC_fra: `
    Service de nettoyage, désinfection et enlèvement des débris après rénovation/construction.
    Après le travail difficile de construction ou de rénovation d'une propriété, il y a un désir de pouvoir en profiter. Par conséquent, notre travail consiste à s'assurer que vous puissiez profiter du lieu de vos rêves et à confier tout ce qui concerne la post-rénovation/construction entre nos mains.
    
    Le service de nettoyage comprend :
        `,
    description_AC_list_fra: [
        'Enlèvement des débris légers tels que les sacs de débris.',
        'Nettoyage des résidus de construction sur le sol (plâtre, peinture, mastic, etc.)',
        'Nettoyage des résidus de construction sur les murs',
        'Nettoyage des résidus de construction sur les fenêtres',
        'Lavage des fenêtres (*)',
        'Enlèvement des déchets et recyclage',
        'Aspiration des sols',
        'Lavage, séchage et désinfection des sols',
        'Nettoyage et désinfection des surfaces',
        'Nettoyage des surfaces en verre',
        'Nettoyage et désinfection des armoires, tiroirs et étagères',
        'Nettoyage et désinfection de la cuisine et des appareils de cuisine (*)',
        'Nettoyage et désinfection des placards',
        'Lavage, séchage et désinfection des salles de bains.',
        'Nettoyage et désinfection des lampes et des objets décoratifs (*)',
        'Nettoyage des bords et des plinthes',
        'Aspiration des coins (*) Époussetage des appareils de cuisine (cuisinière, four, réfrigérateur, micro-ondes et hotte) et des objets décoratifs (peintures, horloges, objets suspendus).',
    ],
    description_HP_fra: `
    Service de nettoyage haute pression Notre service de nettoyage haute pression dépasse les attentes en matière de propreté et de revitalisation. Grâce à notre expertise et à des équipements de pointe, nous redonnons vie à vos espaces extérieurs avec un nettoyage en profondeur qui élimine non seulement la saleté visible mais aussi les impuretés incrustées. Nos techniques de haute pression garantissent des résultats impeccables sur diverses surfaces telles que les pavés, les murs, les terrasses et même les véhicules. En optant pour notre service, vous offrez à votre propriété un nouvel éclat qui résiste aux éléments et au temps, tout en préservant son intégrité structurelle. Découvrez comment notre service de nettoyage haute pression peut transformer votre environnement extérieur en un espace accueillant et attrayant, prêt à être apprécié pleinement. `,
    description_HP_eng: `
    High-Pressure Cleaning Service
    Our high-pressure cleaning service goes beyond mere cleanliness—it revitalizes your outdoor spaces with a depth of cleaning that removes not just surface dirt but ingrained impurities. Utilizing advanced equipment and expertise, we breathe new life into surfaces such as pavers, walls, terraces, and vehicles. Our high-pressure techniques deliver impeccable results that withstand the elements and time, preserving structural integrity while enhancing visual appeal. Discover how our high-pressure cleaning service can transform your outdoor environment into a welcoming and attractive space, ready to be fully enjoyed.
     `,
    description_HP_esp: `
    Servicio de Limpieza a Alta Presión
Nuestro servicio de limpieza a alta presión va más allá de la limpieza convencional: revitaliza sus espacios exteriores con una profundidad de limpieza que elimina no solo la suciedad superficial, sino también las impurezas incrustadas. Utilizando equipos avanzados y experiencia especializada, damos nueva vida a superficies como adoquines, paredes, terrazas y vehículos. Nuestras técnicas de alta presión ofrecen resultados impecables que resisten los elementos y el tiempo, preservando la integridad estructural mientras realzan el atractivo visual. Descubra cómo nuestro servicio de limpieza a alta presión puede transformar su entorno exterior en un espacio acogedor y atractivo, listo para ser disfrutado plenamente.
    `,
}