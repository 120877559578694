import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FiGlobe } from 'react-icons/fi'; // Import icons for language selection
import { useLanguage } from '../../hooks/useLanguage';
import { useModal } from '../../hooks/useModal';
import './EcoservNavbar.css'; // Import CSS file for Navbar styles

interface Props {
    logoSrc?: string;
}

export const EcoservNavbar = ({ logoSrc }: Props) => {
    const [isSticky, setIsSticky] = useState(false);
    const [expanded, setExpanded] = useState(false); // State to manage navbar collapse
    const { languageSelected, onChangeLanguage } = useLanguage();
    const { switchModal } = useModal();

    const switchModalFromNav = () => {
        switchModal();
        setExpanded(false); // Close navbar after clicking on the link
    };

    const navbarHeight = 90; // Adjust this value based on your actual navbar height

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const offsetTop = section.offsetTop - navbarHeight;
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
            setExpanded(false); // Close navbar after clicking on the link
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onChangeLanguages = (event: React.SyntheticEvent<HTMLElement>) => {
        const selectedLanguage = event.currentTarget.getAttribute('data-language');
        if (selectedLanguage) {
            onChangeLanguage(selectedLanguage);
        }
        setExpanded(false); // Close navbar after selecting a language
    };

    return (
        <Navbar expanded={expanded} expand="lg" variant="dark" className={isSticky ? 'ecoserv-navbar sticky' : 'ecoserv-navbar'} bg="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src={logoSrc}
                        height="30"
                        className="d-inline-block align-top"
                        alt="Ècoserv"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" arial-expanded={expanded}>
                    <Nav className="ml-auto">
                        <Nav.Link onClick={() => scrollToSection('services')} href="#services" className="nav-link">
                            {languageSelected === "French" ? "Services" : languageSelected === "Spanish" ? "Servicios" : "Services"}
                        </Nav.Link>
                        <Nav.Link onClick={() => scrollToSection('estimation')} href="#estimation" className="nav-link">
                            {languageSelected === "French" ? "Estimation" : languageSelected === "Spanish" ? "Estimación" : "Estimation"}
                        </Nav.Link>
                        <Nav.Link onClick={switchModalFromNav} href="#" className="nav-link">
                            {languageSelected === "French" ? "Nous joindre" : languageSelected === "Spanish" ? "Contacto" : "Contact"}
                        </Nav.Link>
                        <NavDropdown title={<FiGlobe className="language-icon" />} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={onChangeLanguages} data-language="French" active={languageSelected === 'French'}>
                                {languageSelected === "French" ? "Français" : languageSelected === "Spanish" ? "Francés" : "French"}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={onChangeLanguages} data-language="English" active={languageSelected === 'English'}>
                                {languageSelected === "French" ? "Anglais" : languageSelected === "Spanish" ? "Inglés" : "English"}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={onChangeLanguages} data-language="Spanish" active={languageSelected === 'Spanish'}>
                                {languageSelected === "French" ? "Espagnol" : languageSelected === "Spanish" ? "Español" : "Spanish"}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};